import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { OwnerService } from './owner-logo.service';
import { OwnerFamilyService } from './owner-family.service';
import { BusinessNameService } from './businessName.service';
import { AnalyticsService } from './google-analytics.service';
import { BrandingResponse, AnimalResponse } from '../models/branding.interface';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retryWhen, switchMap, delay, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
  })
  export class LogoService {
    MAX_RETRIES = 3;

    constructor(
      private owner: OwnerService,
      private animal: OwnerFamilyService,
      private logger: NGXLogger,
      private businessNameService: BusinessNameService,
      private ga: AnalyticsService
    ) {}

    fetchShelterLogoSrc(): Observable<{ logoUrl: string; brandingDays: number; daysSinceAdoption: number }> {
      return this.owner.getBranding().pipe(
        switchMap((brandingResponse: BrandingResponse) => {
          const brandingDays = brandingResponse.configuration.shelterBrandingDays;
          const defaultLogoId = brandingResponse.configuration.defaultLogoId;

          this.businessNameService.legalName = brandingResponse.legalName;
          this.businessNameService.accountId = brandingResponse.accountId;
          this.businessNameService.shelterBrandingDays = brandingDays;

          this.ga.trackBusinessDetails(brandingResponse.accountId, brandingResponse.legalName);

          return this.animal.getAnimalInfo().pipe(
            switchMap((animalResponse: AnimalResponse[]) => {
              const daysSinceAdoption = animalResponse[0]?.daysSinceAdoption;

              if (daysSinceAdoption !== undefined && daysSinceAdoption <= brandingDays) {
                return this.owner.getLogo().pipe(
                  map((response: any) => {
                    if (response && response.length > 0) {
                      const logo = response.find((l: any) => l.logoId === defaultLogoId);
                      return { logoUrl: logo ? logo.logoUrl : response[0].logoUrl, brandingDays, daysSinceAdoption };
                    } else {
                      this.logger.error('No logos found.');
                      return { logoUrl: '', brandingDays, daysSinceAdoption };
                    }
                  })
                );
              } else {
                this.logger.log('Days since adoption exceed branding days. Skipping logo fetch.');
                return throwError('Days since adoption exceed branding days');
              }
            })
          );
        }),
        retryWhen(errors =>
          errors.pipe(
            delay(1000),
            take(this.MAX_RETRIES),
            switchMap(error => {
              if (error instanceof HttpErrorResponse && error.status === 401) {
                this.logger.warn('Encountered 401 error. Retrying...');
                return throwError(error);
              } else {
                this.logger.error('An error occurred while fetching data:', error);
                return throwError(error);
              }
            })
          )
        ),
        catchError(error => {
          this.logger.error('An error occurred while fetching data:', error);
          return throwError(error);
        })
      );
    }
  }
