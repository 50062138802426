import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { OwnerFamilyService } from "../../service/owner-family.service";
import * as ownerActions from "./owner.actions";
import { catchError, exhaustMap, map, of, retry } from "rxjs";

@Injectable()
export class OwnerEffects {
  constructor(private actions: Actions,
              private ownerFamilyService: OwnerFamilyService) {
  }

  loadOwner$ = createEffect(() => {
    return this.actions.pipe(
      ofType(ownerActions.LOAD_OWNER),
      exhaustMap(() => this.ownerFamilyService.getOwnerInfo()
        .pipe(
          map(owner => ({
            type: ownerActions.GET_OWNER,
            owner: owner
          })),
          retry(3),
          catchError(error => of({
            type: ownerActions.GET_OWNER_ERROR,
            errorCode: error.status,
            errorMessage: error.message
          }))
        )
      )
    );
  });
}
