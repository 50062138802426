import { createReducer, on } from "@ngrx/store";
import * as shelterActions from "./shelter.actions";
import * as _ from "lodash";
import { BrandingResponse } from "../../models/branding.interface";

export interface ShelterStateModel {
  initialState: boolean,
  success: boolean,
  errorCode: string,
  errorMessage: string
  shelter: BrandingResponse | null
}

export const sheltersInitialState: ShelterStateModel = {
  initialState: true,
  success: true,
  errorCode: '',
  errorMessage: '',
  shelter: null
};

export const sheltersReducer = createReducer(
  sheltersInitialState,
  on(shelterActions.loadShelters, (state) => state),
  on(shelterActions.getShelters, (state, { shelter }) => ({
    ..._.cloneDeep(state),
    initialState: false,
    success: true,
    errorCode: '',
    errorMessage: '',
    shelter
  })),
  on(shelterActions.getSheltersError, (state, { errorCode, errorMessage }) => ({
    ..._.cloneDeep(state),
    initialState: false,
    success: false,
    errorCode,
    errorMessage
  }))
);
