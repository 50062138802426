import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import { PetszelPasswordResetComponent } from './petszel-password-reset/petszel-password-reset.component';
import { ConfirmResetComponent } from './confirm-reset/confirm-reset.component';
import { RouterModule } from '@angular/router';
import { ResetPasswordEmailComponent } from './reset-password-email/reset-password-email.component';
import { PetszelLoginComponent } from './petszel-login/petszel-login.component'; // Import the PetszelLoginComponent
import { NavigationComponent } from './navigation/navigation.component';
import { PetszelPoweredComponent } from './petszel-powered/petszel-powered.component';
import { PrimaryBtnComponent } from './primary-btn/primary-btn.component';
import { PetszelPoweredNobrandComponent } from './petszel-powered-nobrand/petszel-powered-nobrand.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { PetszelPartnerSummaryComponent } from '../../../petszel-staff/src/app/components/petszel-partner-summary/petszel-partner-summary.component';
import { LayoutModule } from '@angular/cdk/layout';
import { PlacesListComponent } from './places-list/places-list.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { LoadingComponent } from './loading/loading.component';
import { ToastsContainerComponent } from './toasts-container/toasts-container.component';
import { NgbToastModule } from "@ng-bootstrap/ng-bootstrap";
import { FooterNavComponent } from './footer-nav/footer-nav.component';
import { SortableHeaderDirective } from './directives/sortable-header.directive';

@NgModule({
  declarations: [
    PetszelPasswordResetComponent,
    ConfirmResetComponent,
    ResetPasswordEmailComponent,
    PetszelLoginComponent,
    NavigationComponent,
    PetszelPoweredComponent,
    PrimaryBtnComponent,
    PetszelPoweredNobrandComponent,
    PetszelPartnerSummaryComponent,
    PlacesListComponent,
    ErrorMessageComponent,
    LoadingComponent,
    ToastsContainerComponent,
    FooterNavComponent,
    SortableHeaderDirective
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    GoogleMapsModule,
    LayoutModule,
    FormsModule,
    MatDialogModule,
    NgbToastModule
  ],
  exports: [
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    PetszelPasswordResetComponent,
    ConfirmResetComponent,
    ResetPasswordEmailComponent,
    PetszelLoginComponent,
    NavigationComponent,
    FooterNavComponent,
    PetszelPoweredComponent,
    PrimaryBtnComponent,
    PetszelPoweredNobrandComponent,
    GoogleMapsModule,
    PetszelPartnerSummaryComponent,
    PlacesListComponent,
    ErrorMessageComponent,
    LoadingComponent,
    ToastsContainerComponent,
    SortableHeaderDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PetszelSharedModule {}
