import { createAction, props } from "@ngrx/store";
import { BrandingResponse } from "../../models/branding.interface";

export const LOAD_SHELTERS = 'Load Shelters';
export const LIST_OF_SHELTERS = 'List Of Shelters';
export const LIST_OF_SHELTERS_ERROR = 'List Of Shelters Error';

export const loadShelters = createAction(LOAD_SHELTERS);
export const getShelters = createAction(LIST_OF_SHELTERS,
  props<{ shelter: BrandingResponse }>());
export const getSheltersError = createAction(LIST_OF_SHELTERS_ERROR,
  props<{ errorCode: string, errorMessage: string }>());
