import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { NGXLogger } from "ngx-logger";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedLibraryService } from "../../../../../petszel-shared/src/lib/services/shared.config";
import { OwnerFamilyService } from "../../service/owner-family.service";
import { Owner } from "../../models/communications.interface";
import { Store } from "@ngrx/store";
import { AppStateModel } from "../../shared-store/app-state.model";
import { selectOwner } from "../../shared-store/owner/owner.selectors";
import { setOwner } from "../../shared-store/owner/owner.actions";

@Component({
  selector: 'app-terms-and-conditions-modal',
  templateUrl: './terms-and-conditions-modal.component.html',
  styleUrls: ['./terms-and-conditions-modal.component.css']
})
export class TermsAndConditionsModalComponent implements OnInit, OnDestroy {
  ownerInfo!: any;
  termsOfServiceURL: string = '';
  loading = false;

  private getOwnerSubs!: Subscription;
  private manageOwnerSubs!: Subscription;
  private termsOfServiceSubs!: Subscription;

  constructor(private logger: NGXLogger,
              private activeModalService: NgbActiveModal,
              private ownerFamilyService: OwnerFamilyService,
              private sharedLibraryService: SharedLibraryService,
              private store: Store<AppStateModel>) {
  }

  ngOnInit(): void {
    this.getOwnerSubs = this.store.select(selectOwner)
      .subscribe(owner => this.ownerInfo = owner);
    this.buildTOSUrl();
  }

  ngOnDestroy(): void {
    if (this.getOwnerSubs) { this.getOwnerSubs.unsubscribe(); }
    if (this.manageOwnerSubs) { this.manageOwnerSubs.unsubscribe(); }
    if (this.termsOfServiceSubs) { this.termsOfServiceSubs.unsubscribe(); }
  }

  acceptTerms(): void {
    this.loading = true;
    const ownerData: Owner = {
      ...this.ownerInfo as Owner,
      acceptedTerms: true
    };

    this.manageOwnerSubs = this.ownerFamilyService.putOwnerInfo(ownerData).subscribe({
      next: (owner: any) => {
        this.store.dispatch(setOwner({ owner }));

        this.loading = false;
        this.activeModalService.close();
      },
      error: err => {
        this.loading = false;
        this.logger.log('Error while accepting terms of service', err);
      }
    });
  }

  private buildTOSUrl(): void {
    const environment = this.sharedLibraryService.getSharedLibraryConfig().environmentType;
    if (environment === 'local' || environment === 'development') {
      this.termsOfServiceURL = 'https://dev.petszel.com/terms-of-service';
    } else if (environment === 'beta') {
      this.termsOfServiceURL = 'https://beta.petszel.com/terms-of-service';
    } else {
      this.termsOfServiceURL = 'https://petszel.com/terms-of-service';
    }
  }
}
