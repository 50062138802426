import { createReducer, on } from "@ngrx/store";
import * as loadStateActions from "./app-state.actions";

export interface LoadStateModel {
  isLoaded: boolean;
}

export const loadStateInitialState: LoadStateModel = {
  isLoaded: false
}

export const loadStateReducer = createReducer(
  loadStateInitialState,
  on(loadStateActions.loadState, (state) => state),
  on(loadStateActions.setLoadState, (state, { isLoaded }) => ({
    ...state,
    isLoaded
  }))
);
