import { createReducer, on } from "@ngrx/store";
import * as petActions from "./pet.actions";
import * as _ from "lodash";
import { AnimalResponse } from "../../models/branding.interface";

export interface PetStateModel {
  initialState: boolean,
  success: boolean,
  errorCode: string,
  errorMessage: string
  pets: AnimalResponse[]
}

export const petsInitialState: PetStateModel = {
  initialState: true,
  success: true,
  errorCode: '',
  errorMessage: '',
  pets: []
};

export const petsReducer = createReducer(
  petsInitialState,
  on(petActions.loadPets, (state) => state),
  on(petActions.getPets, (state, { pets }) => ({
    ..._.cloneDeep(state),
    initialState: false,
    success: true,
    errorCode: '',
    errorMessage: '',
    pets
  })),
  on(petActions.getPetsError, (state, { errorCode, errorMessage }) => ({
    ..._.cloneDeep(state),
    initialState: false,
    success: false,
    errorCode,
    errorMessage
  }))
);
