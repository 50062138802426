import { createReducer, on } from "@ngrx/store";
import * as selectedVariablesActions from './selected-variables.actions';

export interface SelectedVariablesModel {
  animalId: string;
}

export const initialSelectedVariables: SelectedVariablesModel = {
  animalId: ''
};

export const selectedVariablesReducer = createReducer(
  initialSelectedVariables,
  on(selectedVariablesActions.resetAnimalId, (state) => ({
    ...state,
    animalId: ''
  })),
  on(selectedVariablesActions.setAnimalId, (state, { animalId }) => ({
    ...state,
    animalId
  }))
);
