import { createReducer, on } from "@ngrx/store";
import * as logoActions from "./logo.actions"
import { Logo } from "../../models/logo.interface";


export interface LatestAdoptionLogoStateModel {
  initialState: boolean,
  logo: Logo | null
}

export const latestAdoptionLogoInitialState: LatestAdoptionLogoStateModel = {
  initialState: true,
  logo: null
};

export const logoReducer = createReducer(
  latestAdoptionLogoInitialState,
  on(logoActions.setLatestAdoptionLogo, (state, { logo }) => ({
    ...state,
    initialState: false,
    logo
  }))
);
