import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as shelterActions from "./shelter.actions";
import { catchError, exhaustMap, map, of, retry } from "rxjs";
import { OwnerService } from "../../service/owner-logo.service";

@Injectable()
export class ShelterEffects {
  constructor(private actions: Actions,
              private ownerService: OwnerService) {
  }

  loadShelters$ = createEffect(() => {
    return this.actions.pipe(
      ofType(shelterActions.LOAD_SHELTERS),
      exhaustMap(() => this.ownerService.getBranding()
        .pipe(
          map(shelter => ({
            type: shelterActions.LIST_OF_SHELTERS,
            shelter
          })),
          retry(3),
          catchError(error => of({
            type: shelterActions.LIST_OF_SHELTERS_ERROR,
            errorCode: error.status,
            errorMessage: error.message
          }))
        )
      )
    );
  });
}
