import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { Observable, of } from 'rxjs';
import { setLoadState } from "../shared-store/app-state.actions";
import * as petActions from "../shared-store/pet/pet.actions";
import * as ownerActions from "../shared-store/owner/owner.actions";
import * as shelterActions from "../shared-store/shelter/shelter.actions"; // Import 'Observable' and 'of' from RxJS

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  redirectRoute: string = '/owner-dashboard';
  badCredentials = false;

  constructor(private router: Router) {}

  isLoggedIn(): boolean {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwt_decode<any>(token);
        const credentialType = decodedToken['petszel-credentialtype'];
        return credentialType === 'PetszelConnectClient';
      } catch (error) {
        return false;
      }
    }
    return false;
  }

  canActivate(): boolean {
    if (this.isLoggedIn()) {
      if (this.redirectRoute === '/owner-dashboard') {
        return true; // Allow access to the route
      } else {
        this.badCredentials = true;
        localStorage.removeItem('token'); // Remove the token since the authentication is not successful
        return false; // Block access to the route
      }
    } else {
      this.router.navigateByUrl('/'); // Redirect to the home URL
      return false; // Block access to the route
    }
  }

  redirectCallback(credentialType: string) {
    if (
      credentialType === 'PetszelConnectClient' &&
      this.redirectRoute === '/owner-dashboard'
    ) {
      localStorage.setItem('token', 'placeholder'); // Store a placeholder token
      this.router.navigateByUrl(this.redirectRoute);
    } else {
      this.badCredentials = true;
      localStorage.removeItem('token'); // Remove the token since the authentication is not successful
      this.router.navigateByUrl('/'); // Redirect to '/' for bad credentials
    }
  }

  // In AuthGuardService
  logoutAndRedirect(): Observable<void> {
    localStorage.removeItem('token'); // Remove the token
    this.router.navigateByUrl('/'); // Redirect to the login page
    return of(undefined);
  }

  // setUserEmailFromToken(token: string): void {
  //   try {
  //     const decodedToken = jwt_decode<any>(token);
  //     const email =
  //       decodedToken[
  //         'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
  //       ];
  //     const encodedEmail = btoa(email); // Base64 encode the email
  //     localStorage.setItem('userEmail', encodedEmail);
  //   } catch (error) {
  //     console.error('Error decoding token: ', error);
  //   }
  // }

  // getUserEmail(): string | null {
  //   const encodedEmail = localStorage.getItem('userEmail');
  //   return encodedEmail ? atob(encodedEmail) : null; // Base64 decode the email, or return null if not set
  // }
}
