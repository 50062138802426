import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { OwnerFamilyService } from "../../service/owner-family.service";
import * as petActions from "./pet.actions";
import { catchError, exhaustMap, map, of, retry } from "rxjs";

@Injectable()
export class PetEffects {
  constructor(private actions: Actions,
              private ownerFamilyService: OwnerFamilyService) {
  }

  loadPets$ = createEffect(() => {
    return this.actions.pipe(
      ofType(petActions.LOAD_PETS),
      exhaustMap(() => this.ownerFamilyService.getAnimalInfo()
        .pipe(
          map(pets => ({
            type: petActions.LIST_OF_PETS,
            pets: pets as any[]
          })),
          retry(3),
          catchError(error => of({
            type: petActions.LIST_OF_PETS_ERROR,
            errorCode: error.status,
            errorMessage: error.message
          }))
        )
      )
    );
  });
}
