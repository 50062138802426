import { createAction, props } from "@ngrx/store";

export const LOAD_PETS = 'Load Pets';
export const LIST_OF_PETS = 'Get List Of Pets';
export const LIST_OF_PETS_ERROR = 'List Of Pets Error';

export const loadPets = createAction(LOAD_PETS);
export const getPets = createAction(LIST_OF_PETS, props<{ pets: any[] }>());
export const getPetsError = createAction(LIST_OF_PETS_ERROR,
  props<{ errorCode: string, errorMessage: string }>());
